import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchAll } from '../../services/vadr-scores';
import { VadrScores } from '../../types/vadr-scores';
import { DATACORE_VECTOR_TYPE } from '../../types/datacore';

const KEY = 'vadr-scores';

export function useVadrScores({
  orgId,
  pid,
  indicationId,
  options,
}: {
  orgId: string;
  pid: string;
  indicationId?: string;
  options?: Omit<UseQueryOptions<VadrScores[]>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<VadrScores[]>({
    queryKey: [DATACORE_VECTOR_TYPE, KEY, orgId, pid, indicationId],
    queryFn: () => fetchAll(orgId, pid, indicationId),
    enabled: !!orgId && !!pid && !!indicationId,
    ...options,
  });
}
