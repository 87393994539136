import { fetchOne } from '../../services/flow-versions';
import { FlowVersion } from '../../types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export const FLOW_VERSIONS_KEY = 'flow-versions';

export function useFlowVersion({
  orgId,
  pid,
  flowId,
  flowVersionId,
  options,
}: {
  orgId: string;
  pid: string;
  flowId: string;
  flowVersionId: string;
  options?: Omit<UseQueryOptions<FlowVersion>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<FlowVersion>({
    queryKey: [FLOW_VERSIONS_KEY, orgId, pid, flowId, flowVersionId],
    queryFn: () => fetchOne({ oid: orgId, pid, flowId, flowVersionId }),
    enabled: !!orgId && !!pid,
    ...options,
  });
}
