import { FlowVersion } from '../../types';
import { api } from '../../utils/api';

export function fetchOne({
  flowId,
  flowVersionId,
  oid,
  pid,
}: {
  flowId: string;
  flowVersionId: string;
  oid: string;
  pid: string;
}) {
  return api
    .get<FlowVersion>(
      `/orgs/${oid}/${pid}/flows/${flowId}/versions/${flowVersionId}`,
    )
    .then(({ data }) => data);
}
