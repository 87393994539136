import { domtoimage } from '..';
import FileSaver from 'file-saver';

/**
 * Exports the target DOM element to a PNG file.
 * `domtoimage` should export what the user sees on the screen.
 * So adjustments to how a user wants the image to look should be done on the UI.
 * Use the scale prop to increase or decrease the resolution of the PNG.
 */
export function handleSaveToPng({
  targetElement,
  scale = 4,
  fileName,
  onError,
}: {
  targetElement: HTMLElement;
  scale?: number;
  fileName: string;
  onError?: (error: Error) => void;
}) {
  domtoimage
    .toBlob(targetElement, {
      scale,
    })
    .then(function (blob: Blob) {
      FileSaver.saveAs(blob, fileName);
    })
    .catch(function (error: Error) {
      onError?.(error);
    });
}
