import { fetchAll } from '../../services/flows';
import { Flow } from '../../types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export const FLOWS_KEY = 'flows';

export function useFlows({
  orgId,
  pid,
  options,
}: {
  orgId: string;
  pid: string;
  options?: Omit<UseQueryOptions<Flow[]>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<Flow[]>({
    queryKey: [FLOWS_KEY, orgId, pid],
    queryFn: () => fetchAll(orgId, pid),
    enabled: !!orgId && !!pid,
    ...options,
  });
}
