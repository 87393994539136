import { VadrScores } from '../../types/vadr-scores';
import { api } from '../../utils/api';

export async function fetchAll(
  oid: string,
  pid: string,
  indicationId?: string,
): Promise<VadrScores[]> {
  return api
    .get<VadrScores[]>(
      `/orgs/${oid}/${pid}/vadr/scores?indication=${indicationId}`,
    )
    .then(({ data }) => data);
}
